import React from 'react'
import PropTypes from 'prop-types'
import BurgerIcon from './burger-icon'
import CrossIcon from '../cross-icon'
import { globalHistory } from '@reach/router/lib/history'

import './mobilenav.css'

export default class MobileNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }


  componentDidMount() {
    globalHistory.listen(() => this.closeMenu)
    window.onkeydown = this.listenForClose.bind(this)
    window.onclick = this.listenForClose.bind(this)
  }

  componentWillUnmount () {
    //if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
    window.onkeydown = null
    window.onclick = null
    document.body.classList.toggle('nav-open', false)
  }

  listenForClose (e) {
    const event = e || window.event

    if (this.state.isOpen && (event.key === 'Escape' || event.keyCode === 27)) {
      this.toggleMenu()
    }
  }

  closeMenu () {
    this.setState({ isOpen: false })
    document.body.classList.toggle('nav-open', false)
  }

  toggleMenu () {
    this.setState({ isOpen: !this.state.isOpen })
    document.body.classList.toggle('nav-open', !this.state.isOpen)
  }

  render () {
    let items
    const className = this.props.className
    const isOpen = this.state.isOpen

    if (this.props.children) {
      items = React.Children.map(this.props.children, (item, index) => {
        const extraProps = {
          key: index
        }

        return React.cloneElement(item, extraProps)
      })
    }

    return (
      <div className={className + ' burger-nav'}>
        <div className='menu-wrap' style={{
          opacity: isOpen ? '1' : '0',
          visibility: isOpen ? 'visible' : 'hidden'
        }}>
          <div className='menu'>
            {items}
          </div>
          <CrossIcon onClick={this.toggleMenu} />
        </div>
        <BurgerIcon onClick={this.toggleMenu} />
      </div>
    )
  }
}
