import React from 'react'
import Link from 'gatsby-link'
import Icon from '../components/icon'
import { StaticQuery, graphql } from "gatsby"

const footerQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { id: { regex: "/blog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default class Footer extends React.Component {
  render () {
    return (
      <StaticQuery 
        query={footerQuery} 
        render={data => 
          <div>
            <section id='talk'>
              <div className='container'>
                <h2>Let&#x2019;s talk Pool Renovation!</h2>
                <Link className='btn btn-white' to='/contact/'>
                  Ask a Question
                </Link>
              </div>
            </section>
            <footer>
              <div className='container'>
                <div className='links'>
                  <Link to='/story'>Our Story</Link>
                  <Link to='/team'>Our Team</Link>
                  <Link to='/gallery'>Gallery</Link>
                  <Link to='/projects'>Projects &amp; Testimonial</Link>
                  <Link to='/blog'>Blog</Link>
                  <Link to='/contact'>Talk to us</Link>
                </div>
                <div className='links'>
                  <Link to='/work/'>Our Work</Link>
                  <Link className='depth1' to='/work/equipment/'>Equipment</Link>
                  <Link className='depth1' to='/work/outdoor-living/'>Outdoor Living</Link>
                  <Link className='depth1' to='/work/pool-interiors/'>Pool Interiors</Link>
                  <Link className='depth1' to='/work/pool-surrounds/'>Pool Surrounds</Link>
                </div>
                <div className='aux'>
                  <div className='blog'>
                    <h4>Latest Blog</h4>
                    {data?.edges?.posts.map(({ node: post }) => (
                      <Link to={post.fields.slug} key={post.id}>
                        <small>{post.frontmatter.date}</small>
                        <p>
                          {post.frontmatter.title}
                        </p>
                      </Link>
                    ))}
                  </div>
                  <div className='footerContact'>
                    <a href='tel:1300141413'>
                      <Icon src='phone' /> 1300 14 14 13
                    </a>
                    <Link to='/contact'>
                      <Icon src='map-pin' /> 101 Bradfield Rd. Lindfield NSW 2070
                    </Link>
                  </div>
                </div>
                <div className='icons'>
                  <Link to='/' className='brand'>
                    <img
                      src='/assets/logo-white.png'
                      className='img-responsive'
                      alt='Sydney Pool Renovations'
                    />
                  </Link>
                  <a className='social' href='https://www.facebook.com/PoolRenovations'>
                    <Icon src='facebook' />
                  </a>
                  <a className='social' href='https://twitter.com/PoolRenovations'>
                    <Icon src='twitter' />
                  </a>
                </div>
              </div>
            </footer>
          </div>
        }
      />
    )
  }
}


