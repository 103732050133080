import React from 'react'
import Link from 'gatsby-link'

import MobileNav from './mobile-nav'

const Nav = () => (
  <nav>
    <Link activeClassName='active' to='/story'>Our Story</Link>
    <Link activeClassName='active' to='/team'>Our Team</Link>
    <Link activeClassName='active' to='/work'>Our Work</Link>
    <Link activeClassName='active' to='/gallery'>Our Gallery</Link>
    <Link activeClassName='active' to='/projects'>Projects &amp; Testimonials</Link>
    <Link activeClassName='active' to='/blog'>Blog</Link>
    <Link activeClassName='active' to='/contact'>Talk to us</Link>
  </nav>
)

const Contact = (props) => (
  <ul className={props.className}>
    <li className='phone'><a href='tel:1300141413'>1300 14 14 13</a></li>
    <li className='address'><Link to='/contact/'>101 BRADFIELD RD LINDFIELD NSW 2070</Link></li>
  </ul>
)

const Header = () => (
  <header>
    <MobileNav className='mobile-nav'>
      <Link to='/'>
        <img src='/assets/logo-white.png' className='brand img-responsive' alt='Sydney Pool Renovations' />
      </Link>
      <Nav />
      <Contact className='mobile-contact' />
    </MobileNav>
    <div className='container'>
      <Link to='/'>
        <img src='/assets/logo.png' className='brand img-responsive' alt='Sydney Pool Renovations' />
      </Link>
      <Nav />
      <Contact className='contact' />
    </div>
  </header>
)

export default Header
