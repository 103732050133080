import React, {Component} from 'react'

import facebook from './icons/facebook.svg'
import twitter from './icons/twitter.svg'
import phone from './icons/phone.svg'
import mapPin from './icons/map-pin.svg'

const getIcon = (icon) =>
  ({
    phone,
    twitter,
    facebook,
    'map-pin': mapPin,
  }[icon]);

export default class Icon extends Component {
  render () {
    const {src, ...props} = this.props
    return (
      <div className='icon' {...props}>
        <img alt="" src={getIcon(src)} />
      </div>
    )
  }
}
