import React from 'react'

import './cross.css'

const CrossIcon = (props) => (
  <div className={`cross-button ${props.className}`} onClick={props.onClick}>
    <span className='cross' style={{transform: 'rotate(45deg)'}} />
    <span className='cross' style={{transform: 'rotate(-45deg)'}} />
  </div>
)

export default CrossIcon
