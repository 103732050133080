import React from 'react'

export default class BurgerIcon extends React.Component {
  constructor (...args) {
    super(...args)
    this.state = { hover: false }
    this.handleHover = this.handleHover.bind(this)
  }

  getLineStyle (index) {
    return {
      top: 20 * (index * 2) + '%',
      opacity: this.state.hover ? 0.6 : 1
    }
  }

  handleHover () {
    this.setState({hover: !this.state.hover})
  }

  render () {
    return (
      <div className='burger-button'
        onClick={this.props.onClick}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}>
        <div className='burger-bars-wrapper'>
          <span className='burger-bars' style={this.getLineStyle(0)} />
          <span className='burger-bars' style={this.getLineStyle(1)} />
          <span className='burger-bars' style={this.getLineStyle(2)} />
        </div>
        <span className='text'>
            Menu
        </span>
      </div>
    )
  }
}
